import useTranslation from 'next-translate/useTranslation'
import clsx from 'clsx'
import { useEffect } from 'react'
import { BoxPickUpStation } from '@ancon/wildcat-types'

import Button from '../../../app/components/Button'
import BodyText from '../../../app/components/BodyText'
import HeadingText from '../../../app/components/HeadingText'
import Skeleton from '../../../app/components/Skeleton'

import styles from './BoxPickupSections.module.scss'

type BoxPickupSectionsProps = {
  onChangeSection: (section: BoxPickUpStation) => void
  selectedSection?: BoxPickUpStation
  sections: BoxPickUpStation[]
  isLoading?: boolean
}

export default function BoxPickupSections({
  onChangeSection,
  selectedSection,
  sections,
  isLoading = false,
}: BoxPickupSectionsProps) {
  const { t } = useTranslation('common')

  useEffect(() => {
    // If there is only one section, select it
    if (!selectedSection && sections.length === 1) {
      onChangeSection(sections[0])
    }
  }, [onChangeSection, sections, selectedSection])

  return (
    <div className={styles.boxPickupSections}>
      <HeadingText as="h2">
        {t('newOrderTimeConfigureModal.boxPickup.where')}
      </HeadingText>
      <BodyText color="body-1" className={styles.sectionInfoText}>
        {t('newOrderTimeConfigureModal.boxPickup.whereInfo')}
      </BodyText>
      <div className={styles.orderSectionRow}>
        {isLoading
          ? new Array(3).fill(0).map((_, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Skeleton className={styles.skeleton} key={index} />
            ))
          : sections.map(section => (
              <Button
                variant="secondary"
                onClick={() => onChangeSection(section)}
                className={clsx({
                  [styles.selected]: selectedSection?.id === section.id,
                })}
                key={section.id}
              >
                <HeadingText
                  as="p"
                  size="h3"
                  color="heading-1"
                  className={styles.capitalize}
                >
                  {section.name}
                </HeadingText>
              </Button>
            ))}
        {!isLoading && sections.length === 0 && (
          <BodyText color="body-1" className={styles.noSectionsText}>
            {t('newOrderTimeConfigureModal.boxPickup.noSections')}
          </BodyText>
        )}
      </div>
    </div>
  )
}
