import React, { useMemo } from 'react'
import {
  IMapProps,
  IMarkerProps,
  Map,
  Marker,
  Polygon,
  mapEventHandler,
} from 'google-maps-react'

import { Coordinates } from '../../outlets/types'

interface MapViewProps
  extends Omit<
    IMapProps,
    'google' | 'initialCenter' | 'center' | 'streetViewControl'
  > {
  polygonPoints?: Coordinates[]
  markerCoordinates?: Coordinates
  markerConfig?: IMarkerProps & {
    onDragend?: mapEventHandler
  }
}

export default function MapView({
  polygonPoints,
  markerCoordinates,
  markerConfig,
  ...mapProps
}: MapViewProps) {
  const deliveryRangePoints = useMemo(() => {
    if (polygonPoints) {
      return polygonPoints.map(point => ({
        lat: point.latitude,
        lng: point.longitude,
      }))
    }
    return undefined
  }, [polygonPoints])

  const selectedCoordinatesLatLng = useMemo(() => {
    if (markerCoordinates) {
      return {
        lat: markerCoordinates.latitude,
        lng: markerCoordinates.longitude,
      }
    }
    return undefined
  }, [markerCoordinates])

  return (
    // @ts-ignore - map props are not correctly defined
    <Map
      google={window.google}
      zoom={14}
      initialCenter={selectedCoordinatesLatLng}
      center={selectedCoordinatesLatLng}
      streetViewControl={false}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...mapProps}
    >
      {!!polygonPoints && (
        <Polygon
          paths={deliveryRangePoints}
          // TODO: Get values from generated theme
          strokeColor="#00FF00"
          fillColor="#00FF00"
          strokeOpacity={0.5}
          strokeWeight={2}
          fillOpacity={0.25}
        />
      )}
      {selectedCoordinatesLatLng && (
        <Marker
          position={selectedCoordinatesLatLng}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...markerConfig}
        />
      )}
    </Map>
  )
}
