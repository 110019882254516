import {
  OrderFormat,
  OutletListItem,
  ServiceTime,
  ServiceTimeKindType,
} from '@ancon/wildcat-types'
import { useMemo, useState } from 'react'
import { getOutletPickerTimesForWeek } from '@ancon/wildcat-utils/outlet'
import useTranslation from 'next-translate/useTranslation'
import moment from 'moment'
import clsx from 'clsx'
import { PickerDay } from '@ancon/wildcat-utils/outlet/types'

import Button from '../../../app/components/Button'
import HeadingText from '../../../app/components/HeadingText'
import BodyText from '../../../app/components/BodyText'
import Selectable, { SelectableType } from '../../../app/components/Selectable'

import styles from './OrderTimeSelectionStep.module.scss'

type OrderTimeSelectionStepProps = {
  selectedOrderFormat: OrderFormat
  initialServiceTime?: ServiceTime
  onApply: (serviceTime: ServiceTime) => void
  outletListItem: OutletListItem
}

function getInitialPickerTimeIndex(
  pickerDays: PickerDay[],
  serviceTime: string,
) {
  const serviceTimeMoment = moment(serviceTime)
  const dayTimesIndex = pickerDays.findIndex(pickerDay =>
    moment(pickerDay[0].timestamp)
      .startOf('day')
      .isSame(serviceTimeMoment.clone().startOf('day')),
  )
  if (dayTimesIndex >= 0) {
    const timeIndex = pickerDays[dayTimesIndex].findIndex(
      dayTime => serviceTimeMoment.valueOf() === dayTime.timestamp,
    )

    return [dayTimesIndex, timeIndex]
  }

  return [0, 0]
}

export default function OrderTimeSelectionStep({
  selectedOrderFormat,
  onApply,
  initialServiceTime,
  outletListItem,
}: OrderTimeSelectionStepProps) {
  const { t, lang } = useTranslation('common')
  const isBoxPickup = selectedOrderFormat === OrderFormat.BoxPickup

  const pickerDays = useMemo(
    () =>
      getOutletPickerTimesForWeek(outletListItem, selectedOrderFormat, lang),
    [outletListItem, selectedOrderFormat, lang],
  )
  const [initialSelectedDayIndex, initialSelectedTimeIndex] =
    initialServiceTime?.time
      ? getInitialPickerTimeIndex(pickerDays, initialServiceTime.time)
      : [0, 0]
  const [selectedDayIndex, setSelectedDayIndex] = useState<number>(
    initialSelectedDayIndex,
  )
  const [selectedTimeIndex, setSelectedTimeIndex] = useState<number>(
    initialSelectedTimeIndex,
  )
  const pickerTimesForSelectedDay =
    pickerDays[selectedDayIndex < 0 ? 0 : selectedDayIndex]
  const selectedPickerTime = pickerTimesForSelectedDay
    ? pickerTimesForSelectedDay[selectedTimeIndex || 0]
    : undefined
  const pickerTimeMoment = moment(selectedPickerTime?.timestamp)

  function handleOnClickDay(dayIndex: number) {
    if (selectedDayIndex !== dayIndex) {
      setSelectedDayIndex(dayIndex)
      setSelectedTimeIndex(0)
    }
  }

  function handleOnChangeTime(timeIndex: number) {
    if (selectedTimeIndex !== timeIndex) {
      setSelectedTimeIndex(timeIndex)
    }
  }

  function handleOnSchedule() {
    const selectedTime = pickerTimesForSelectedDay[selectedTimeIndex]

    onApply({
      kind: ServiceTimeKindType.AtSpecifiedTime,
      time: moment(selectedTime.timestamp).toISOString(),
    })
  }

  function handleOrderNow() {
    onApply({
      kind: ServiceTimeKindType.AsSoonAsPossible,
      time: '',
    })
  }

  return (
    <>
      <div className={styles.modalBody}>
        <div className={styles.dayRow}>
          {pickerDays.map((pickerDay, index) => {
            const { timestamp } = pickerDay[0]
            const date = moment(timestamp)

            return (
              <Button
                variant="secondary"
                onClick={() => handleOnClickDay(index)}
                className={clsx({
                  [styles.selected]: selectedDayIndex === index,
                })}
                key={timestamp}
              >
                <div>
                  <HeadingText
                    as="p"
                    size="h3"
                    color="heading-1"
                    className={styles.capitalize}
                  >
                    {date.calendar({
                      sameDay: `[${t('today')}]`,
                      nextDay: `[${t('tomorrow')}]`,
                      nextWeek: 'dddd',
                    })}
                  </HeadingText>
                  <BodyText as="span" color="body-1">
                    {date.format('MMM D')}
                  </BodyText>
                </div>
              </Button>
            )
          })}
        </div>
        <div className={styles.pickerTimeSection}>
          {pickerTimesForSelectedDay?.map?.((pickerTime, index) => {
            const timeFrom = moment(pickerTime.timestamp)
            const timeTo = timeFrom.clone().add(10, 'minutes')

            return (
              <div
                className={styles.pickerTimeRow}
                key={pickerTime.timestamp}
                onClick={() => handleOnChangeTime(index)}
                role="presentation"
              >
                <BodyText as="span" color="body-1">
                  {`${timeFrom.format('LT')} - ${timeTo.format('LT')}`}
                </BodyText>
                <Selectable
                  type={SelectableType.RadioButton}
                  checked={index === selectedTimeIndex}
                  onClick={() => handleOnChangeTime(index)}
                />
              </div>
            )
          })}
        </div>
      </div>

      <div className={styles.modalFooter}>
        <div>
          <HeadingText
            as="p"
            size="h3"
            color="heading-1"
            className={styles.capitalize}
          >
            {`${pickerTimeMoment.calendar({
              sameDay: `[${t('today')}]`,
              nextDay: `[${t('tomorrow')}]`,
              nextWeek: 'dddd',
            })}, ${pickerTimeMoment.format(
              'D MMMM',
            )} • ${pickerTimeMoment.format('LT')} - ${pickerTimeMoment
              .clone()
              .add(10, 'minutes')
              .format('LT')}`}
          </HeadingText>
        </div>
        <div>
          {!isBoxPickup && (
            <Button variant="secondary" onClick={handleOrderNow} outlined>
              {t('orderConfigurationModal.orderNow')}
            </Button>
          )}
          <Button onClick={handleOnSchedule}>
            {t('orderConfigurationModal.schedule')}
          </Button>
        </div>
      </div>
    </>
  )
}
